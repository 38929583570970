import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { InitializerProvider } from "./initialization/initializer-provider";
import { initalizerFactory } from "./initialization/initializer-factory";
import { HttpClientModule } from "@angular/common/http";
import { ProfilesService } from "./profiles/profiles-service";
import { GraphsLayoutService } from "./graph-layout/graph-layout.service";
import { ExportGraphService } from "./export/export-graph.service";
import { DecimalPipe } from "@angular/common";

@NgModule({
  imports: [HttpClientModule],
  exports: [],
  declarations: [],
  providers: [
    InitializerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
    ProfilesService,
    GraphsLayoutService,
    ExportGraphService,
    DecimalPipe,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
