import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BfcTranslationService } from "@bfl/components/translation";
import * as Highstock from "highcharts/highstock";
import { Serie } from "../../graph/model/serie.interface";

@Injectable()
export class GraphsLayoutService {

  constructor(private translateService: BfcTranslationService) {
  }

  public combineLayoutWithDataLineChart(series: Serie[], that): Highstock.Options {
    const translateService = this.translateService;
    const localString = this.getLangLocalString();
    const marginLeftDesktop = 75;
    const marginLeftMobile = 40;

    const options: any = {
      chart: {
        zoomType: "x",
        marginLeft: marginLeftDesktop,
        spacingLeft: 0,
        spacingRight: 0,
        height: 600,
        type: "line",
        spacingTop: 10,
        style: { // !important is overwritten when defined in global scss
          fontSize: "16px",
          fontWeight: "normal",
          fontFamily: "bkw-regular-font",
        },
      },
      scrollbar: {
        enabled: false,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      legend: {
        x: marginLeftDesktop,
        padding: 0,
        margin: 24,
        itemMarginBottom: 5,
        enabled: true,
        align: "left",
        itemStyle: { // could not be overridden via scss, as of version 6.1.0
          fontSize: "16px",
          fontWeight: "normal",
          fontColor: "#222222",
          fontFamily: "bkw-regular-font",
        },
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function () {
          if (this.legendLine) {
            this.legendLine.attr("stroke-width", 16);
            this.legendLine.attr("stroke-height", 16);
          }
          return this.name;
        },
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          showInNavigator: true,
          marker: {
            enabled: false,
            fillColor: "#002D69",
            lineWidth: 0,
            states: {
              hover: {
                lineWidthPlus: 0,
              },
            },
            symbol: "circle",
          },
          states: {
            hover: {
              halo: {
                size: 0,
              },
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          const current = {
            x: this.x,
            y: this.y,
            points: this.points,
          };

          // Prepare HTML output
          let html: string = "";
          html += "<div class=\"graph-tooltip\" >";
          html += "<div class=\"headline\">";
          html += moment(current.points[0].point.x).locale(localString).format("DD. MMMM YYYY <br>HH:mm") + " " + translateService.translate("GRAPH_COMPONENT.OCLOCK");
          html += "</div>";
          html += "<div  >";
          current.points.forEach((point: any) => {
            let axisTitle = "";
            if (point.series.yAxis && point.series.yAxis.axisTitle) {
              axisTitle = point.series.yAxis.axisTitle.textStr;
            }

            html += "<div class=\"serie\" >" + point.series.name + "</div>";
            html += "<div class=\"value-unit\" style=\"color:" + point.color + ";font-size: 1.3em\">" + point.y.toLocaleString("de-CH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " " + axisTitle;
            html += "</div>";
          });
          html += "</div>";
          html += "</div>";
          return html;
        },
        useHTML: true,
        shared: true,
        split: false,
        backgroundColor: "rgba(255,255,255,0.75)",
        borderWidth: 0,
        padding: 0,
        shadow: false,
        crosshairs: false,
        followPointer: false,
        followTouchMove: false,
        style: {},
        positioner: (labelWidth, labelHeight, point) => {
          let tooltipX, tooltipY;
          if (point.plotX + labelWidth > that.chart.plotWidth) {
            tooltipX = point.plotX + that.chart.plotLeft - labelWidth - 6;
          } else {
            tooltipX = point.plotX + that.chart.plotLeft + 6;
          }

          tooltipY = that.chart.plotSizeY - 282;
          return {
            x: tooltipX,
            y: tooltipY,
          };
        },
        shape: "square",
      },
      xAxis: {
        gridLineWidth: 1,
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%b %Y",
          year: "%Y",
        },
        title: {
          style: {
            color: "#222222",
          },
          text: null,
        },
        crosshair: {
          color: "#002D69",
          snap: true,
          width: 1,
          zIndex: 50,
        },
        labels: {
          style: {
            fontSize: "14px",
            color: "#222222",
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          format: "",
          style: {
            fontSize: "14px",
            color: "#222222",
          },
        },
        showFirstLabel: false,
        title: {
          align: "middle",
          margin: 10,
          rotation: 270,
          text: "",
          style: {
            color: "#222222",

          },
        },
        opposite: false,
      },
      responsive: [
        {
          rules: [
            {
              condition: {
                maxWidth: 599,
              },
              chartOptions:
                                {
                                  marginLeft: marginLeftMobile,
                                },

            },
          ],
        },
      ],
      rangeSelector: {
        verticalAlign: "top",
        allButtonsEnabled: true,
        inputEnabled: false,
        buttonSpacing: 50,
        buttonPosition: {
          align: "left",
          x: 0,
          y: 0,
        },
        padding: 10,
        buttonTheme: { // styles for the buttons
          fill: "none",
          stroke: "none",
          "stroke-width": 0,
          r: 8,
          style: {
            color: "#222222",
            fontSize: 16,
            fontWeight: "normal",
          },
          states: {
            hover: {
              fill: "none",
            },
            select: {
              fill: "none",
              style: {
                color: "#0080C6",
                fontWeight: "bold",
              },
            },
            // disabled: { ... }
          },
        },
        // buttons removed because it is not the expected filtering for this project
        buttons: [],
        labelStyle: {
          display: "none", // hack to hide the 'zoom' text; in previous versions there was a better way - https://stackoverflow.com/questions/33606462/hide-zoom-text-on-rangeselector/50872890#50872890
        },
      },
      series,
    };

    return options;
  }

  public getEarliestDate(serie: number[][]) {
    Math.min.apply(serie.map((data) => {
      return data[0];
    }));
  }

  public getLatestDate(serie: number[][]) {
    Math.max.apply(serie.map((data) => {
      return data[0];
    }));
  }

  private getLangLocalString(): string {
    if (this.translateService.language.toLocaleLowerCase() === "fr") {
      return "fr-CH";
    }
    return "de-CH"; // default lang
  }
}
