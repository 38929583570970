import { Component, OnInit } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeCH from "@angular/common/locales/de-CH";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
    registerLocaleData(localeCH, "ch");
  }

}
