import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { NgModule } from "@angular/core";
import { translations } from "./config/translations/translations";

const routes: Routes = [
  {
    path: "",
    redirectTo: "select-profile",
    pathMatch: "full",
  },
  {
    path: "select-profile",
    loadChildren: () => import("./profiles/select-profile/select-profile.module").then((m) => m.SelectProfileModule),
    canActivate: [MsalGuard],
  },
  {
    path: "import-profile",
    loadChildren: () => import("./profiles/import-profile/import-profile.module").then((m) => m.ImportProfileModule),
    canActivate: [MsalGuard],
  },
  {
    path: "merge-profiles",
    loadChildren: () => import("./profiles/merge-profiles/merge-profiles.module").then((m) => m.MergeProfilesModule),
    canActivate: [MsalGuard],
  },
  {
    path: "add-curve",
    loadChildren: () => import("./profiles/add-curve/add-curve.module").then((m) => m.AddCurveModule),
    canActivate: [MsalGuard],
  },
  {
    path: "show-profile",
    loadChildren: () => import("./profiles/show-profile/show-profile.module").then((m) => m.ShowProfileModule),
    canActivate: [MsalGuard],
  },
  {
    path: "call-option-parameters",
    loadChildren: () => import("./call-option-parameters/call-option-parameters.module").then((m) => m.CallOptionParametersModule),
    canActivate: [MsalGuard],
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "**",
    redirectTo: "error",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }