import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { IPublicClientApplication } from "@azure/msal-browser";
import { InitializerProvider } from "./initializer-provider";
import { MSALProvider } from "./msal-provider";

export function initalizerFactory(provider: InitializerProvider) {
  return () => provider.initialize();
}

export function initMSALInstance(provider: MSALProvider): IPublicClientApplication {
  return provider.initMSALInstanceFactory();
}

export function initMSALInterceptorConfig(provider: MSALProvider): MsalInterceptorConfiguration {
  return provider.initMSALInterceptorConfigFactory();
}

export function initMSALGuardConfig(provider: MSALProvider): MsalGuardConfiguration {
  return provider.initMSALGuardConfigFactory();
}