import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class LastgangUIErrorHandler implements ErrorHandler {

  handleError(error) {

    const errorMessages: string[] = error.error  ? error.error.ErrorMessages : [];

    if (error.status === 400 && errorMessages.length > 0) {
      alert(errorMessages.join("\n\n"));
    }

    // eslint-disable-next-line no-console
    console.error(error);
  }
}