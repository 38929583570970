import { Injectable } from "@angular/core";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class InitializerProvider {

  constructor(private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  public initialize() {
    this.bfcGoogleAnalyticsService.initGoogleTagManager(
      this.bfcConfigurationService.configuration.googleTagManagerContainerId,
    );
  }
}
