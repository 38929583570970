import { BrowserCacheLocation, InteractionType } from "@azure/msal-browser";
import { LastgangAppConfiguration } from "../configuration";

export const IS_IE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const LOCAL_ENV: LastgangAppConfiguration = {
  restApiUrl: "https://bkw-futurecrm-services-energyoffer-profileapp-test.azurewebsites.net/",
  msalConfig: {
    auth: {
      clientId: "46515310-61c8-42eb-80be-373abe96698a",
      authority: "https://login.microsoftonline.com/75326e1e-19fd-405d-9280-2a4a19924519",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: IS_IE, // set to true for IE 11
    },
  },
  msalAngularInterceptorConfig: {
    interactionType: InteractionType.Redirect,
    // array declaration as any is a workaround, because new Map() was recognized only as typeof object from msal
    protectedResourceMap: [
      ["https://bkw-futurecrm-services-energyoffer-profileapp-test.azurewebsites.net", ["api://46515310-61c8-42eb-80be-373abe96698a/user_impersonation"]],
    ] as any,
  },
  msalAngularGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        "user.read",
        "api://46515310-61c8-42eb-80be-373abe96698a/user_impersonation",
      ],
    },
    loginFailedRoute: "./unauthorized",
  },
};
