export const FR_TRANSLATIONS = {
  "SERVICE_NAME": "Lastgang UI",
  "GRAPH_COMPONENT": {
    "OCLOCK": "Uhr",
    "ZOOM": {
      "WEEK": "Woche",
      "MONTH": "Monat",
      "QUARTER": "Quartal",
      "YEAR": "Jahr",
    },
    "EXPORT": "Daten exportieren",
    "LOADING": "Daten werden geladen...",
  },
  "PROFILE_TYPES": {
    "UNSPECIFIED": " ",
    "STANDARD": "Standardlastprofil",
    "HISTORIC": "Historisches Profil",
    "DELIVERY": "Lieferprofil",
    "RESIDUAL": "Restprofil",
    "SAMMLER_KAUF": "Sammler Kauf",
    "SAMMLER_VERKAUF": "Sammler Verkauf",
    "FWC_SWITZERLAND": "Fwc Schweiz in EUR",
    "FWC_GERMANY": "Fwc Deutschland in EUR",
    "FX": "Fx",

  },
  "ERRORS": {
    "REQUIRED": "Dieses Feld ist erforderlich",
    "INVALID_FORMAT": "Das Format der Daten in der ausgewählten Datei ist falsch",
  },
  "SELECT_PROFILE": {
    "TITLE": "Profil auswählen",
    "NO_PROFILES": "Keine Profile vorhanden",
    "APPLY": "Übernehmen",
    "IMPORT_PROFILE": "Profil importieren",
    "TABLE": {
      "HEADER": {
        "PROFILE_TYPE": "Profiltyp",
        "QUOTE_NAME": "Angebot",
        "QUOTE_NUMBER": "Angebotsnummer",
        "CUSTOMER_NAME": "Kunde",
        "MP_DESCRIPTION": "Messpunktbezeichnung",
        "FROM": "Von",
        "TO": "Bis",
        "CONSUMPTION": "Verbrauch im 1. Jahr (MWh)",
        "CREATED_ON": "Erstellt am",
      },
    },
  },
  "IMPORT_PROFILE": {
    "TITLE": "Lastgang einlesen",
    "IMPORT_PROFILE_DESCRIPTION": "Lastgangdaten aus CSV-Datei einlesen",
    "PROFILE_FORMAT": "Lastgangformat",
    "SELECTED_FILE_PLACEHOLDER": "Ausgewähltes File",
    "FORMAT_TEMPLATE": "Format-Template",
    "PROFILE_TYPE": "Profiltyp",
    "READ_IN": "Einlesen",
    "PROFILE_ERROR_MESSAGE": "Es ist ein Fehler beim Lesen des Lastgangs aufgetreten, bitte prüfen Sie den Lastgang. Wenn das Problem weiterhin besteht können Sie sich beim FutureCRM Team melden (Email: SfutureCRM@bkw.ch).",
    "PROFILE_TEMPLATE": {
      "KW_HOUR": "Profil-Vorlage_Std_kW",
      "KW_QUARTER_HOUR": "Profil-Vorlage_1-4Std_kW",
      "MW_HOUR": "Profil-Vorlage_Std_MW",
      "KWH_QUARTER_HOUR_SAP": "Vorlage_1-4Std_kWh_SAP",
    },
  },
  "MERGE_PROFILES": {
    "TITLE": "Summenlastgang erstellen",
    "MERGE_PROFILES_DESCRIPTION": "Aus den Messwerten verschiedener Zählpunkte und der jeweils " +
            "jüngsten Prognose einen Summenlastgang in einem definierten Zeitraum erstellen",
    "NAME_PLACEHOLDER": "Name",
    "CREATE": "Erstellen",
    "DATEPICKER": {
      "FROM_PLACEHOLDER": "Zeitraum von",
      "TO_PLACEHOLDER": "Zeitraum bis",
    },
  },
  "ADD_CURVE": {
    "TITLE": "Zeitreihen Upload",
    "ADD_CURVE_DESCRIPTION": "Datei für den Upload auswählen:",
    "UPLOAD": "Hochladen",
    "SUCCESS_MESSAGE": "Das '{{profileType}}' - Profil wurde erfolgreich importiert.",
  },
  "SHOW_PROFILE": {
    "TITLE": "Profil visualisieren",
    "DATA": {
      "DATE_RANGE": "Betrachtungszeitraum",
      "NUMBER_OF_VALUES": "Anzahl Werte",
      "AVERAGE_CAPACITY": "Durchschnittliche Leistung [kW]",
      "MAX_CAPACITY": "Max. Leistung [MW]",
      "MIN_CAPACITY": "Min. Leistung [MW]",
      "CONSUMPTION_RATE": "Verbrauchsmenge [MWh]",
    },
    "TIME_RANGE_START": "Anfangsdatum",
    "TIME_RANGE_END": "Enddatum",
  },
  "CALL_OPTION_PARAMETERS": {
    "TITLE": "Parameter für die Berechnung der Call-Option",
    "RISK_FREE_INTEREST_PLACEHOLDER": "Risikoloser Zins",
    "VOLATILITY_PLACEHOLDER": "Volatilität",
    "UPDATE_VALUES": "Werte aktualisieren",
  },
};