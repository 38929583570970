import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import { Serie } from "../../graph/model/serie.interface";
import { DecimalPipe } from "@angular/common";

@Injectable()
export class ExportGraphService {

  constructor(private decimalPipe: DecimalPipe) {
  }

  exportCSV(series: Serie[], minDate?: number, maxDate?: number) {
    let csv = "";
    const delimiter = ";";

    if (minDate && maxDate) {
      // Crop series data to range between min and max date
      series.forEach((serie: Serie) => {
        serie.data = serie.data.filter((value: number[]) => {
          const timestamp = value[0];
          return timestamp >= minDate && timestamp <= maxDate;
        });
      });
    }

    // Create CSV heading row (Hardcoded in this case)
    csv += ["Datum", "Uhrzeit", "Wert", "Einheit"].join(delimiter) + "\n";

    // Fill in data rows
    for (let i = 0; i < series[0].data.length; i++) {
      // First two rows are date and time
      csv += moment(series[0].data[i][0]).format("DD.MM.YYYY" + delimiter + "HH:mm") + delimiter;

      // add to row values of each series
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      series.forEach((serie: Serie, index: number) => {
        let value = "0";

        if (serie.data[i][1]) {
          value = this.decimalPipe.transform(serie.data[i][1], "1.3-3");
        }

        csv += value + delimiter;

        // Unit is hardcoded to MWh
        const unit: string = "MWh";
        if (index < series.length - 1) {
          csv += unit + delimiter;
        } else {
          csv += unit + "\n";
        }
      });
    }

    const fileName = moment(new Date()).format("YYYYMMDDHHmmss");
    const csvFile = new Blob([csv], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(csvFile, fileName + ".csv");
  }
}
