import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { MinimizedProfile, Profile } from "../../profiles/model/profile.interface";
import { ResponseBase } from "../../profiles/model/response-base.interface";
import { ProfileTemplate } from "../../profiles/import-profile/model/profile-template.enum";

@Injectable()
export class ProfilesService {

  private restApiUrl: string = this.bfcConfigurationService.configuration.restApiUrl;

  private profileFindMultipleUrl: string = this.restApiUrl + "profile/findMultiple";

  private profileApplyMultipleUrl: string = this.restApiUrl + "profile/applyMultiple";

  private profileAddUrl: string = this.restApiUrl + "profile/add";

  private profileMergeUrl: string = this.restApiUrl + "profile/merge";

  private profileAddCurveUrl: string = this.restApiUrl + "profile/addCurve";

  private profileFindUrl: string = this.restApiUrl + "profile/find";

  private profileGetVolatilityAndRiskfreeInterestUrl: string = this.restApiUrl + "profile/getVolatilityAndRiskfreeInterest";

  private profileSetVolatilityAndRiskfreeInterestUrl: string = this.restApiUrl + "profile/setVolatilityAndRiskfreeInterest";

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  getProfiles(request: ProfileFindMultipleRequest): Observable<ProfileFindMultipleResponse> {
    //TODO: Check if backup ids should stay there
    const parameters = {
      "accountId": request.accountId ? request.accountId : "6819479A-3579-E811-8108-1458D043A400",
      "quoteId": request.quoteId ? request.quoteId : "182C842B-099A-E811-810F-3863BB35ED98",
    };

    return this.httpClient.post<ProfileFindMultipleResponse>(this.profileFindMultipleUrl, parameters,
      { headers: { "Content-Type": "application/json; charset=utf-8" } });
  }

  applyProfiles(request: ProfileApplyMultipleRequest): Observable<ProfileApplyMultipleResponse> {
    const parameters = {
      "profileIds": request.profileIds,
      "quoteId": request.quoteId ? request.quoteId : "182C842B-099A-E811-810F-3863BB35ED98",
      "accountId": request.accountId ? request.accountId : "6819479A-3579-E811-8108-1458D043A400",
    };

    return this.httpClient.post<ProfileApplyMultipleResponse>(this.profileApplyMultipleUrl, parameters,
      { headers: { "Content-Type": "application/json; charset=utf-8" } });
  }

  addProfile(request: ProfileAddRequest): Observable<ProfileAddResponse> {
    const parameters = {
      "accountId": request.accountId ? request.accountId : "6819479A-3579-E811-8108-1458D043A400",
      "quoteId": request.quoteId ? request.quoteId : "182C842B-099A-E811-810F-3863BB35ED98",
      "template": request.template,
      "profile": request.profile,
      "timeSeries": request.timeSeries,
    };

    return this.httpClient.post<ProfileAddResponse>(this.profileAddUrl, parameters,
      { headers: { "Content-Type": "application/json; charset=utf-8" } });
  }

  mergeProfiles(request: ProfileMergeRequest): Observable<ProfileMergeResponse> {
    const parameters = {
      "quoteId": request.quoteId ? request.quoteId : "182C842B-099A-E811-810F-3863BB35ED98",
      "accountId": request.accountId ? request.accountId : "6819479A-3579-E811-8108-1458D043A400",
      "name": request.name,
      "from": request.from,
      "to": request.to,
      "profileIds": request.profileIds ? request.profileIds : ["C8D5A4F5-7AA7-E811-8117-3863BB35ED98", "C8D5A4F5-7AA7-E811-8117-3863BB35ED99"],
    };

    return this.httpClient.post<ProfileMergeResponse>(this.profileMergeUrl, parameters,
      { headers: { "Content-Type": "application/json; charset=utf-8" } });
  }

  addCurve(request: ProfileAddCurveRequest): Observable<ProfileAddCurveResponse> {
    const parameters = {
      "profile": request.profile,
      "timeSeries": request.timeSeries,
    };

    return this.httpClient.post<ProfileAddCurveResponse>(this.profileAddCurveUrl, parameters,
      { headers: { "Content-Type": "application/json; charset=utf-8" } });
  }

  findProfile(request: ProfileFindRequest): Observable<ProfileFindResponse> {
    const parameters = {
      "profileId": request.profileId ? request.profileId : "8E85C96F-0B9A-E811-810F-3863BB35ED98",
      "quoteId": request.quoteId ? request.quoteId : "182C842B-099A-E811-810F-3863BB35ED98",
    };

    return this.httpClient.post<ProfileFindResponse>(this.profileFindUrl, parameters,
      { headers: { "Content-Type": "application/json; charset=utf-8" } });
  }

  getVolatilityAndRiskfreeInterest(): Observable<GetVolatilityAndRiskfreeInterestResponse> {
    return this.httpClient.post<GetVolatilityAndRiskfreeInterestResponse>(
      this.profileGetVolatilityAndRiskfreeInterestUrl,
      {},
      { headers:
          { "Content-Type": "application/json; charset=utf-8" },
      },
    );
  }

  setVolatilityAndRiskfreeInterest(request: SetVolatilityAndRiskfreeInterestRequest): Observable<ResponseBase> {
    const parameters = {
      "bkw_riskfreeinterest": request.bkw_riskfreeinterest,
      "bkw_volatility": request.bkw_volatility,
    };

    return this.httpClient.post<ResponseBase>(this.profileSetVolatilityAndRiskfreeInterestUrl, parameters,
      { headers: { "Content-Type": "application/json; charset=utf-8" } });
  }
}

export interface ProfileFindMultipleRequest {
  accountId: string;
  quoteId: string;
}

export interface ProfileFindMultipleResponse extends ResponseBase {
  profiles: Profile[];
}

export interface ProfileApplyMultipleRequest {
  profileIds: string[];
  quoteId: string;
  accountId: string;
}

export interface ProfileApplyMultipleResponse extends ResponseBase {
  appliedProfilesCount: number;
}

export interface ProfileAddRequest {
  quoteId: string;
  accountId: string;
  template: ProfileTemplate;
  profile: MinimizedProfile;
  timeSeries: number[];
}

export interface ProfileAddResponse extends ResponseBase {
  profileId: string;
}

export interface ProfileMergeRequest {
  quoteId: string;
  accountId: string;
  name: string;
  from: string;
  to: string;
  profileIds: string[];
}

export interface ProfileMergeResponse extends ResponseBase {
  profileId: string;
}

export interface ProfileAddCurveRequest {
  profile: MinimizedProfile;
  timeSeries: number[];
}

export interface ProfileAddCurveResponse extends ResponseBase {
  profileId: string;
}

export interface ProfileFindRequest {
  profileId: string;
  quoteId: string;
}

export interface ProfileFindResponse extends ResponseBase {
  profile: Profile;
  timeSeries: number[];
}

export interface GetVolatilityAndRiskfreeInterestResponse extends ResponseBase {
  bkw_riskfreeinterest: number;
  bkw_volatility: number;
}

export interface SetVolatilityAndRiskfreeInterestRequest {
  bkw_riskfreeinterest: number;
  bkw_volatility: number;
}