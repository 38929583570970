import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST } from "@bfl/components/authentication";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";
import { translations } from "./config/translations/translations";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { configuration } from "./config/configuration";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { OverlayModule } from "@angular/cdk/overlay";
import { LastgangUIErrorHandler } from "./core/error/lastgang-ui-error-handler.service";
import { MsalApplicationModule } from "./core/auth/msal-application-module";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    BfcTranslationModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    CoreModule,
    SharedModule,
    OverlayModule,
    MsalApplicationModule,
    BfcSinglePageLayoutModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: LastgangUIErrorHandler,
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
